import axios from "../../config/axiosConfig";
import { TOAST_SHOW } from "../actions/toastActions";
import { RESET_REWARDS_DATA_SUCCESS, RESET_REWARDS_DATA_FAILED, SET_REWARD_DAY_SUCCESS, SET_REWARD_DAY_FAILED  } from "../actions/debugActions";
import { call, put } from "redux-saga/effects";

export function* resetDailyRewards() {
  try {
    console.log("resetDailyRewards");
    const { data, status } = yield call(axios.get, "daily_reset");
    if (status === 200 || status === 201 || status === 202) {
        localStorage.removeItem("dailyRewardTimeStamp");
        console.log('resetDailyRewards done:', data);
        yield put({ type: TOAST_SHOW, payload: { message: "Updated", severity: "Danger", show: true } });
		yield put({  type: RESET_REWARDS_DATA_SUCCESS, payload: data });         
    }
  } catch (e) {
    yield put({ type: RESET_REWARDS_DATA_FAILED });
  }
}

export function* setDailyRewardsDays(payload) {
  try {
    console.log("setDailyRewardsDays:", payload.days);
    const daysAgo = Date.now() - (payload.days * 24 * 60 * 60 * 1000);
    const { data, status } = yield call(axios.get, `set_daily_day/${daysAgo}`);
    if (status === 200 || status === 201 || status === 202) {
        localStorage.setItem("dailyRewardTimeStamp", daysAgo);
        console.log('setDailyRewardsDays done:', data);
        yield put({ type: TOAST_SHOW, payload: { message: "Updated", severity: "Danger", show: true } });
        yield put({ type: SET_REWARD_DAY_SUCCESS, payload: data });
    }
  } catch (e) {    
    console.log(e);
    yield put({ type: SET_REWARD_DAY_FAILED });
  }
}
