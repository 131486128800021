import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import "./style.css";
import { useTranslation } from 'react-i18next';

const AkedoGames = () => {
  useBackButton("/boost-rewards", "/home");
  const dispatch = useDispatch();

  const {i18n} = useTranslation();

  useEffect(() => {
    preventCloseWithSwipe();
    dispatch(getPopupMessageAction());
  }, []);

  const auth_token = sessionStorage.getItem('token');
  const direct_link = `https://client.akedo.gg/category-custom/19000192/${auth_token}/${i18n.language}`;
  // const direct_link = `https://react-dev.akedo.gg/category-custom/19000192/${auth_token}/${i18n.language}`;
  // const direct_link = `http://localhost:3000/category-custom/19000192/${auth_token}/${i18n.language}`;


  return (
    <div className="overflow-y-auto bg-[#161A1C] h-[calc(100vh_-_100px)] pb-0">
		<div className="GamesPage h-full text-white overflow-auto   coustom-scroll">
				<iframe width="100%" height="100%" src={direct_link} title="Akedo"></iframe> 
		</div>
	</div> 
  )
}

export default AkedoGames
